<template>
  <div>
    <div class="container">
      <div id="contents" class="contents">
        <div class="errpage-area">
          <!--수정 109725-->
          <figure class="cer-fig">
            <img src="@/assets/libs/images/err_fig.png" alt="에러이미지" />
          </figure>
          <p class="cer-txt1">
            page not found <br />
            from RedwoodHealth pages.
          </p>
          <div class="btns-bottom">
            <a href="/" class="bt-md bt-red"><span>HOME</span></a>
          </div>
        </div>
      </div>
      <!--/contents-->
    </div>
    <!--/container-->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
